import React from "react";
import PropTypes from "prop-types";
import { useQuery, NetworkStatus } from "@apollo/client";
import Login from "components/Login";
import Loading from "components/Loading";
import { utcNow } from "utils/date";

import useCode from "utils/useCode";
import useAuth from "utils/useAuth";
import Roadmap from "components/Roadmap";
import { ROADMAP_QUERY } from "./roadmapQuery";

const Round = ({ code, setCode }) => {
  const { user, login } = useAuth();

  const { loading, error, data, refetch, networkStatus } = useQuery(
    ROADMAP_QUERY,
    {
      variables: { code },
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        const roadmap = response?.roadmap;

        if (roadmap?.__typename === "Roadmap") {
          if (user?.code !== code) {
            login({
              code,
              officeName: roadmap.office.name,
              crewmates: roadmap.crewmates,
              truck_name: roadmap.truck_name,
              login_at: utcNow(),
            });
          }
        }
      },
    }
  );

  if (error) {
    return <div>Il y a eu une erreur</div>;
  }

  // console.log({ data });

  if (data?.roadmap?.__typename === "Roadmap") {
    return (
      <Roadmap
        roadmap={data.roadmap}
        reloadRoadmap={refetch}
        reloading={networkStatus === NetworkStatus.refetch}
      />
    );
  }

  if (loading && !data?.roadmap) {
    return <Login code={code} setCode={setCode} loading={loading} />;
  }

  if (data?.roadmap?.__typename === "UnknowRoadmap") {
    return <Login code={code} setCode={setCode} hasError loading={loading} />;
  }

  return <Loading />;
};

const RoundLoader = () => {
  const { code, setCode } = useCode();

  if (!code) {
    return <Login code={code} setCode={setCode} />;
  }

  return <Round code={code} setCode={setCode} />;
};

Round.propTypes = {
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
};

export default RoundLoader;
