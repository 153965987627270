import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { RetryLink } from "apollo-link-retry";
import { CachePersistor } from "apollo3-cache-persist";
import QueryStore from "utils/offlineQueryStore";
import { asyncMap } from "@apollo/client/utilities";
import { onError } from "apollo-link-error";
import ApolloStore, { setVersion, getVersion } from "utils/apolloCacheStore";
import QueueLink from "apollo-link-queue";

const APP_VERSION = process.env.REACT_APP_VERSION;

const applyOfflineQueries = async (client) => {
  const offlineQueries = await QueryStore.getAllSorted();

  await Promise.all(
    offlineQueries
      .filter(({ optimisticResponse }) => Boolean(optimisticResponse))
      .map(({ variables, query, optimisticResponse }) =>
        client.mutate({
          variables,
          mutation: query,
          optimisticResponse,
        })
      )
  );
};

const makeLink = (withRetry) => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

  // This link allows to buffer offline aware mutation when needed
  // First we check if the mutation is marked as offline
  // and add it to the cache.
  // Later when the mutation if successful, we remove it from cache
  const offlineBufferLink = new ApolloLink(async (operation, forward) => {
    if (forward === undefined) {
      return null;
    }

    const context = operation.getContext();
    if (context.offline !== undefined) {
      const { operationName, query, variables } = operation;
      const timestamp = Date.now();

      const newTrackedQuery = {
        timestamp,
        query,
        variables,
        operationName,
        optimisticResponse: context.optimisticResponse,
      };

      const cacheKey = context.cacheKey || `${timestamp}_${operationName}`;
      await QueryStore.add(cacheKey, newTrackedQuery);
      operation.setContext({ cacheKey });
      operation.setContext({ skipQueue: true });
    }

    // I searched so long to find a solution to handle async call here...
    // @see https://www.apollographql.com/docs/react/networking/advanced-http-networking/#modifying-response-data
    // This observable allows to manipulate the data returned by the mutation
    // We use it to remove successful mutation from cache,
    return asyncMap(forward(operation), async (data) => {
      const cacheKey = operation.getContext().cacheKey;
      if (cacheKey !== undefined) {
        await QueryStore.remove(cacheKey);
      }

      return data;
    });
  });

  // This is for the service-worker client
  // we do not want to retry when errors occur in background sync
  // because they indicate a server error, not a connection issue.
  if (!withRetry) {
    return ApolloLink.from([offlineBufferLink, httpLink]);
  }

  const errorLink = onError(({ networkError, operation }) => {
    const context = operation.getContext();

    // We register background sync only for offline aware mutations
    if (networkError && context.offline) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((sw) =>
          sw.sync.register("itm-sync")
        );
      }
    } else if (context.offline) {
      const cacheKey = operation.getContext().cacheKey;
      if (cacheKey !== undefined) {
        QueryStore.remove(cacheKey);
      }
    }
  });

  // In our case, the retry link is only here to hold back mutations indefinitely
  // This allows to keep optimistic UI when network is unavailable
  const retryLink = new RetryLink({
    delay: (count, operation) => {
      const context = operation.getContext();
      // Do not retry offline mutations, service-worker will handle them
      // So, we hold back them for a looong time
      if (context.offline) {
        return 1 * 1000;
      }

      return count * 1000 * Math.random();
    },
    attempts: {
      max: Infinity,
      retryIf: (error, operation) => {
        return (
          !(error?.statusCode === 400) &&
          operation.operationName !== "changeTruck"
        );
      },
    },
  });

  const offlineLink = new QueueLink();

  window.addEventListener("offline", () => offlineLink.close());
  window.addEventListener("online", () => offlineLink.open());

  return ApolloLink.from([
    offlineBufferLink,
    retryLink,
    errorLink,
    offlineLink,
    httpLink,
  ]);
};

const makeApolloClient = async (withRetry = true) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Roadmap: {
        fields: {
          am: {
            merge: false,
          },
          pm: {
            merge: false,
          },
        },
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: ApolloStore,
  });

  // When bumping the version, we want to avoid cache structural issues
  // so we purge it
  const currentVersion = await getVersion();
  if (currentVersion === APP_VERSION) {
    await persistor.restore();
  } else {
    await persistor.purge();
    await setVersion(APP_VERSION);
  }

  return new ApolloClient({
    link: makeLink(withRetry),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
};

export default makeApolloClient;

export { applyOfflineQueries };
